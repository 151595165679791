import { request, noTimeOutReq } from '@/utils/request.js'

//获取筛选区县列表
export function getCompanAreaListApi () {
  return request({
    url: `/company/join/base/area/query/list`,
    method: 'post',
  })
}

//获取筛选学校列表
export function getCompanSchoolListApi () {
  return request({
    url: `/company/join/base/school/query/list`,
    method: 'post',
  })
}

//获取筛选班级列表
export function getCompanSchoolClassApi (data) {
  return request({
    url: `/company/join/base/school/class/list`,
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data
  })
}

