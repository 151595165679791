<template>
  <div>
    <div>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="学校名称" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.name"
            placeholder="请输入学校名称"
            @input="reloadCompanSchoolData"
          ></el-input>
        </el-form-item>

        <el-form-item label="学校代码" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.code"
            placeholder="请输入学校代码"
            @input="reloadCompanSchoolData"
          ></el-input>
        </el-form-item>

        <el-form-item label="区县" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.areaCode"
            placeholder="请选择区县"
            @change="reloadCompanSchoolData"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in orgAreaOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="reloadCompanSchoolData"
            >查询</el-button
          >
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-table
        v-loading="loading"
        border
        :data="schoolDataList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="name"
          label="学校名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="code"
          label="代码"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="areaName"
          label="区县"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="联系人"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="联系电话"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" min-width="35px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              @click="getCompanSchoolClass(scope.row.code)"
              >查看班级</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageParam.pageIndex"
        :page-size="pageParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageParam.total"
      ></el-pagination>
    </div>

    <el-row>
      <el-dialog
        title="查看班级"
        :visible.sync="dialogVisible"
        width="40%"
        @close="close"
      >
        <div id="dialog-body">
          <div v-if="companSchoolClassList.length > 0">
            <el-row :gutter="4">
              <el-col
                :span="6"
                v-for="(item, index) in companSchoolClassList"
                :key="index"
                class="school-coloum"
              >
                <div class="school-badge">{{ item.name }}</div>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <center style="font-size: 15px">暂无数据</center>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button
              v-throttle
              type="primary"
              size="medium"
              @click="dialogVisible = false"
              >关闭</el-button
            >
          </div>
        </div>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
import { getCompanSchoolDataApi } from '@/api/schoolManufacturer/schoolData.js'
import {
  getCompanAreaListApi,
  getCompanSchoolClassApi,
} from '@/api/schoolManufacturer/common.js'
export default {
  name: 'schoolData',
  /*  props: {
     schoolData: {
       type: Array,
       required: true
     }
   }, */
  data() {
    return {
      queryParam: {
        name: '',
        code: '',
        areaCode: '',
      },
      loading: false,
      schoolDataList: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      orgAreaOptions: [],
      dialogVisible: false,
      companSchoolClassList: [],
    }
  },
  mounted() {
    this.getCompanAreaList()
    this.init()
  },
  methods: {
    //获取筛选区县列表
    getCompanAreaList() {
      getCompanAreaListApi()
        .then((res) => {
          if (res.success) {
            this.orgAreaOptions = []
            res.data.forEach((item) => {
              this.orgAreaOptions.push(item)
            })
          }
        })
        .catch(() => {})
    },
    init() {
      this.getCompanSchoolData()
    },
    //获取学校数据-分页
    getCompanSchoolData() {
      this.loading = true
      getCompanSchoolDataApi(
        this.pageParam.pageIndex,
        this.pageParam.pageSize,
        this.queryParam
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.schoolDataList = []
            this.schoolDataList = res.data
            this.pageParam.total = 0
            this.pageParam.total = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    //获取筛选班级列表
    getCompanSchoolClass(val) {
      getCompanSchoolClassApi(val).then((res) => {
        this.dialogVisible = true
        if (res.success) {
          this.companSchoolClassList = []
          this.companSchoolClassList = res.data
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    reloadCompanSchoolData() {
      this.pageParam.pageIndex = 1
      this.getCompanSchoolData()
    },
    refresh() {
      this.queryParam.name = ''
      this.queryParam.code = ''
      this.queryParam.areaCode = ''
      this.reloadCompanSchoolData()
    },
    handleCurrentChange(val) {
      this.pageParam.pageIndex = val
      this.getCompanSchoolData()
    },
    handleSizeChange(val) {
      this.pageParam.pageSize = val
      this.getCompanSchoolData()
    },
    close() {
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.school-coloum {
  margin-bottom: 10px;
}
.school-badge {
  text-align: center;
  background-color: rgb(224, 239, 255);
  border-radius: 5px;
  padding: 6px;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.dialog-footer {
  margin-top: 15px;
  text-align: center;
}
</style>
